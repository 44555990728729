import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Aufbewahrungspflicht = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="Für welche Dokumente gilt nach dem Immobilienkauf eine Aufbewahrungspflicht?"
                showCalc={false}
            />
            <Article>
                <p>
                    Du hast gerade den{" "}
                    <Link to="/artikel/kaufvertrag/" target="_blank" rel="noreferrer noopener">
                        Kaufvertrag
                    </Link>{" "}
                    für dein neues Zuhause unterschrieben oder bist vielleicht schon eingezogen? Herzlichen Glückwunsch!
                    Auch nach dem Wohnungs- oder{" "}
                    <Link to="/artikel/hauskauf/" target="_blank" rel="noreferrer noopener">
                        Hauskauf
                    </Link>{" "}
                    gibt es aber noch ein paar Kleinigkeiten zu tun. Dazu gehört die sichere Verwahrung essenzieller
                    Dokumente. Für einige von ihnen gilt sogar die sogenannte Aufbewahrungspflicht. Was darunter zu
                    verstehen ist und auf welche Schriftstücke sie sich tatsächlich bezieht, erfährst du im folgenden
                    Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist die Aufbewahrungspflicht und wofür gilt sie?</h2>
                <p>
                    Die Pflicht, bestimmte Dokumente aufzubewahren, um sie im Bedarfsfall Behörden vorlegen zu können,
                    betrifft größtenteils Unternehmen. Als{" "}
                    <Link to="/artikel/hauseigentuemer/" target="_blank" rel="noreferrer noopener">
                        Immobilieneigentümer:innen
                    </Link>{" "}
                    fallen jedoch auch Privatpersonen unter die Aufbewahrungspflicht. So sind grundsätzlich alle
                    Dokumente, die Arbeiten auf deinem Grundstück bzw. deiner Wohnung belegen, für mindestens zwei Jahre
                    aufzubewahren. Dazu zählen Rechnungen über Reinigungsleistungen, Planungs-, Instandhaltungs- sowie
                    Gartenarbeiten.
                </p>
                <hr />

                <h2>Sollte ich auch Dokumente aufbewahren, die nicht unter die Aufbewahrungspflicht fallen?</h2>
                <p>
                    Die Antwort lautet wenig überraschend: ja. Auch Dokumente, die nicht unmittelbar von der
                    Aufbewahrungspflicht betroffen sind, können im Fall der Fälle essenziell sein. Unbedingt aufheben
                    solltest du als Immobilieneigentümer:in Folgendes:
                </p>
                <ul>
                    <li>Lageplan</li>
                    <li>Grundriss</li>
                    <li>Liegenschaftskarte</li>
                    <li>Bauzeichnungen</li>
                    <li>Baubeschreibung</li>
                    <li>Erschließungsnachweis</li>
                    <li>Vermessungsunterlagen</li>
                    <li>Übergabeprotokolle</li>
                    <li>Energieausweis</li>
                    <li>Grundbuchauszug</li>
                    <li>notariell beurkundeter Kaufvertrag</li>
                    <li>Grundsteuerbescheid</li>
                    <li>Versicherungsnachweise</li>
                    <li>Schornsteinfegerprotokolle</li>
                    <li>Betriebskostenaufstellung</li>
                    <li>Rechnungsbelege für Modernisierung, Sanierung und Instandsetzung</li>
                </ul>
                <p>
                    Bist du Miteigentümer:in einer Wohnungseigentümergemeinschaft - kurz WEG -, sind darüber hinaus
                    diese Dokumente aufzubewahren:
                </p>
                <ul>
                    <li>Dokumente von Eigentümerversammlungen (Protokolle, Beschlüsse etc.)</li>
                    <li>Prüfprotokolle</li>
                    <li>Wohngeldabrechnungen</li>
                    <li>Übersicht der Mieterträge</li>
                    <li>Pacht- oder Mietverträge</li>
                    <li>Teilungserklärung</li>
                    <li>(Auf-)Teilungsplan</li>
                    <li>Abrechnung über die Betriebskosten (sofern diese durch die Hausverwaltung erstellt werden)</li>
                </ul>
                <p>
                    Vermietest du Immobilien privat, so solltest du darüber hinaus auch Übergabeprotokolle, Mietverträge
                    und Betriebskostenabrechnungen aufbewahren - und zwar mindestens noch ein Jahr über die Kündigung
                    des Mietverhältnisses hinaus.{" "}
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"aufbewahrungspflicht"}></BreadcrumbList>
            <ArticleStructuredData
                page={"aufbewahrungspflicht"}
                heading={"Für welche Dokumente gilt nach dem Immobilienkauf eine Aufbewahrungspflicht?"}
            />
        </Layout>
    );
};

export default Aufbewahrungspflicht;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.aufbewahrungspflicht", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
